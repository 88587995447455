import React from "react";
import { SEO, PageLayout, HeroElement, Content } from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class BlogAndFeedbackPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Blog And Feedback"}
              tagline={""}
              ctas={[fishermanBusiness.ctas.phone]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
            />
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"left"}
          >
            <Grid.Column width={16}>
              <Content container={true}>
                <Content.Markup isMarkdown={false}>
                  <p>
                    "Taco Chela is a popular Mexican restaurant in Nashville, TN
                    that has been winning over the hearts of locals and tourists
                    alike with its delicious and authentic cuisine. Located in
                    the trendy neighborhood of west Nashville and downtown
                    Nashville, the restaurant is known for its bustling
                    atmosphere and friendly staff."
                  </p>
                  <p>
                    "One of the things that makes Taco Chela stand out is its
                    commitment to using fresh, high-quality ingredients in all
                    of its dishes. From the tender and flavorful meats to the
                    crisp and colorful vegetables, everything on the menu is
                    prepared with care and attention to detail. But it's not
                    just the food that has made Taco Chela a hit in Nashville.
                    The restaurant's lively ambiance and lively atmosphere make
                    it a great place to hang out with friends and family, and
                    the extensive selection of margaritas and other cocktails
                    makes it a popular spot for a night out."
                  </p>
                  <p>
                    "Despite its popularity, Taco Chela has managed to maintain
                    a laid-back, casual vibe that makes it feel like a
                    neighborhood spot rather than a trendy hotspot. This,
                    combined with its affordable prices and generous portions,
                    has made it a go-to destination for many Nashville
                    residents. Overall, Taco Chela is a must-visit spot for
                    anyone in Nashville who loves Mexican food. With its
                    delicious and authentic cuisine, lively atmosphere, and
                    affordable prices, it's no wonder that it has become such a
                    popular destination in the city. So, if you are planning to
                    visit Nashville, TN and looking for a great Mexican
                    restaurant, be sure to check out Taco Chela."
                  </p>
                </Content.Markup>
              </Content>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      ctas {
        phone {
          text
          link
          internal
        }
      }
    }
    fishermanBusinessWebsitePage(title: { eq: "Blog And Feedback" }) {
      title
    }
  }
`;
